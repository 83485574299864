import React from 'react';
import { SEO } from '../components/SEO';

const IndexPage = () => (
  <div style={{ position: 'relative' }}>
    <SEO title="Home" />
  </div>
);

export default IndexPage;
